import React, { createContext, useContext, useEffect, useState } from "react";
import { saveLocal, readLocal } from "../utils/localStorageHelper";
import { CONSTANTS } from "../ressources/CONSTANTS";
const ArchiveContext = createContext(null);

const initial = {
  options: {
    offset: 0,
    rows: 50,
    page: 0,
  },
  sortOption: {
    sortField: "creation_date",
    sortOrder: -1,
  },
  filters: {
    query: "",
    status: "",
    nature: "",
    scopeList: [],
    intermediateList: [],
    transactionTypeList: [],
  },
};
export const ArchiveContextProvider = ({ children }) => {
  const [archiveContext, setArchiveContext] = useState(
    readLocal(`crescendo-${CONSTANTS.VERSION}-archive-datatable`) || initial
  );

  useEffect(() => {
    if (archiveContext) {
      saveLocal(
        `crescendo-${CONSTANTS.VERSION}-archive-datatable`,
        archiveContext
      );
    }
  }, [archiveContext]);

  const handleCurrentView = (view) => {
    setArchiveContext({
      ...archiveContext,
      currentView: view,
    });
  };

  const setArchiveFilter = (key, value) => {
    setArchiveContext({
      ...archiveContext,
      filters: {
        ...archiveContext.filters,
        [key]: value,
      },
    });
  };

  const setArchiveOptions = (options) => {
    setArchiveContext({
      ...archiveContext,
      options,
    });
  };

  const setArchiveSort = (sortOption) => {
    setArchiveContext({
      ...archiveContext,
      sortOption,
    });
  };

  return (
    <ArchiveContext.Provider
      value={{
        archiveContext,
        setArchiveFilter,
        setArchiveOptions,
        setArchiveSort,
        handleCurrentView,
      }}
    >
      {children}
    </ArchiveContext.Provider>
  );
};

export const useArchiveContext = () => {
  return useContext(ArchiveContext);
};

import React, { useState, useEffect } from "react";
import { useAccountContext } from "../../../contexts/AccountContext";

import Data from "../../../components/Display/Data";
import DataTag from "../../../components/Display/DataTag";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import ManagePassword from "../../../modules/managers/ManagePassword";
import { privateFetch } from "../../../utils/apiHelper";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { saveSession } from "../../../utils/sessionHelper";
import { useNavigate } from "react-router-dom";
import IntermediateDocuments from "../../../modules/user/IntermediateDocuments";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Section from "../../../components/Display/Section";
import { InvestorDetail } from "../../../modules/investor/InvestorDetail";
import ManageInvestor from "../../../modules/managers/ManageInvestor";
import ManageDocument from "../../../modules/managers/ManageDocument";

function AccountPage({ hidden }) {
  const { accountContext, isIntermediateAccount, isInvestorAccount } =
    useAccountContext();
  const navigate = useNavigate();
  const [passwordModuleOpen, setPasswordModuleOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [investor, setInvestor] = useState(undefined);
  const [investorModuleOpen, setInvestorModuleOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Documents
  const [documentList, setDocumentList] = useState([]);
  const [document, setDocument] = useState(null);
  const [mandatoryDefaultType, setMandatoryDefaultType] = useState(undefined);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);

  const updatePassword = async (passwordForm) => {
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/password/`,
      "Votre mot de passe actuel est erroné. Veuillez réessayer",
      "Le mot de passe a bien été modifié.",
      JSON.stringify(passwordForm)
    ).then((res) => {
      setPasswordModuleOpen(false);
      if (res) {
        setLogoutDialogOpen(true);
        setTimeout(() => {
          saveSession(null);
          navigate("/");
        }, 4000);
      }
    });
  };

  const fetchInvestorDetail = async () => {
    await privateFetch("GET", `/investor/${accountContext.id}/`).then((res) => {
      setInvestor(res);
    });
  };

  const updateInvestor = async (investorForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/investor/${investor.id}/`,
      "Une erreur est survenue lors de la modification de l'investisseur",
      "L'investisseur a bien été modifié.",
      JSON.stringify(investorForm)
    ).then(() => {
      setLoading(false);
      setInvestorModuleOpen(false);
      fetchInvestorDetail(investor);
    });
  };

  // Documents
  useEffect(() => {
    fetchInvestorDocumentList();
  }, []);

  const fetchInvestorDocumentList = async () => {
    await privateFetch("GET", `/investor/${accountContext.id}/document/`).then(
      (res) => {
        if (res) {
          setDocumentList(res);
        }
      }
    );
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/investor/${accountContext.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchInvestorDocumentList();
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/investor/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchInvestorDocumentList();
    });
  };

  const handleUploadNewDocument = () => {
    setDocument(undefined);
    setDocumentModuleOpen(true);
    setMandatoryDefaultType(undefined);
  };

  const handleUpdateDocument = (document, mandatoryType) => {
    setDocument(document);
    setDocumentModuleOpen(true);
    setMandatoryDefaultType(mandatoryType);
  };

  useEffect(() => {
    if (isInvestorAccount) {
      fetchInvestorDetail();
    }
  }, []);

  const informationActions = [
    {
      label: "Modifier mon mot de passe",
      icon: "pi pi-lock",
      onClick: () => {
        setPasswordModuleOpen(true);
      },
    },
  ];

  const handleUpdateInvestor = () => {
    setInvestorModuleOpen(true);
  };

  const IntermediateMainContent = (
    <div className="main-content-container">
      <Section label="Informations" actions={informationActions}>
        <div className="data-section-row">
          <Data
            key="alias"
            label={"Nom d'affichage"}
            value={accountContext.alias}
            large
          />
          <Data
            key="email"
            label={"Email"}
            value={accountContext.email}
            large
          />
          <DataTag
            key="type"
            label={"Type de compte"}
            value={
              CONSTANTS.ACCOUNT_TYPES.find(
                (at) => at.value === accountContext.type
              ).label
            }
            bgColor={
              CONSTANTS.ACCOUNT_TYPES.find(
                (at) => at.value === accountContext.type
              ).color
            }
            rounded
          />
          <Data
            key="creationDt"
            label={"Date de création"}
            value={accountContext.creation_date}
            type="date"
          />
        </div>
      </Section>
      {isIntermediateAccount && <IntermediateDocuments />}
    </div>
  );

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Header */}
      <BreadCrumb
        items={[
          {
            label: "Mon compte",
            isActive: true,
          },
        ]}
      />
      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper h-auto overflow-y-scroll">
        {isInvestorAccount ? (
          <InvestorDetail
            investor={investor}
            handleInvestorUpdate={handleUpdateInvestor}
            documentList={documentList}
            handleUploadDocument={handleUploadNewDocument}
            handleUpdateDocument={handleUpdateDocument}
          />
        ) : (
          IntermediateMainContent
        )}
      </div>

      <ManagePassword
        isOpen={passwordModuleOpen}
        handleClose={() => {
          setPasswordModuleOpen(false);
        }}
        updatePassword={updatePassword}
      />

      <ManageInvestor
        loading={loading}
        investor={investor}
        isOpen={investorModuleOpen}
        handleClose={() => setInvestorModuleOpen(false)}
        handleUpdate={updateInvestor}
        isInvestorAccount={isInvestorAccount}
      />

      <ManageDocument
        loading={loading}
        document={document}
        options={CONSTANTS.INVESTOR_DOCUMENTS}
        mandatoryType={mandatoryDefaultType || "other"}
        isOpen={documentModuleOpen}
        handleClose={() => setDocumentModuleOpen(false)}
        uploadDocument={uploadDocument}
        updateDocument={!document?.validated && updateDocument}
        isReadWrite
      />

      <Dialog
        header="Vous allez être déconnecté..."
        visible={logoutDialogOpen}
        style={{ width: "30vw" }}
        draggable={false}
        closable={false}
      >
        <div className="dialog-container">
          <div style={{ width: 30, marginRight: 15 }}>
            <ProgressSpinner style={{ width: "30px", height: "30px" }} />
          </div>
          <p>Veuillez vous reconnecter avec votre nouveau mot de passe</p>
        </div>
      </Dialog>
    </div>
  );
}

export default AccountPage;

import React, { createContext, useContext, useState, useMemo } from "react";

const AccountContext = createContext(null);

export const AccountContextProvider = ({ children }) => {
  const [accountContext, setAccountContext] = useState(null);

  const isLoggedIn = useMemo(() => accountContext !== null, [accountContext]);
  const isAdminAccount = useMemo(
    () => accountContext?.type === "ADMIN",
    [accountContext]
  );
  const isIntermediateAccount = useMemo(
    () => accountContext?.type === "INTERMEDIATE",
    [accountContext]
  );

  const isInvestorAccount = useMemo(
    () => accountContext?.type === "INVESTOR",
    [accountContext]
  );

  const isInternAccount = useMemo(
    () => accountContext?.type === "EMPLOYEE" || isAdminAccount,
    [accountContext]
  );

  return (
    <AccountContext.Provider
      value={{
        accountContext,
        setAccountContext,
        isAdminAccount,
        isIntermediateAccount,
        isLoggedIn,
        isInvestorAccount,
        isInternAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => {
  return useContext(AccountContext);
};

import React, { useState, useEffect, useMemo } from "react";
import { Sidebar } from "primereact/sidebar";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { InputNumber } from "primereact/inputnumber";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import moment from "moment";
import FormInput from "../../components/Form/FormInput";
import { Dropdown } from "primereact/dropdown";
import { privateFetch } from "../../utils/apiHelper";
import { useAccountContext } from "../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { SelectButton } from "primereact/selectbutton";
import Tag from "../../components/Tag";

function ManageTransaction({
  isOpen,
  handleClose,
  handleCreation,
  handleUpdate,
  loading,
  investor,
  transaction,
}) {
  const { accountContext, isIntermediateAccount } = useAccountContext();
  const { getAuthorizedScopeListFromIds, getScopeFromId } =
    useAuthorizationsContext();

  const [transactionTypeList, setTransactionTypeList] = useState([]);
  const [intermediateList, setIntermediateList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [residenceList, setResidenceList] = useState([]);
  const [selectedScope, setSelectedScope] = useState();

  const [amount, setAmount] = useState(0);
  const [transactionType, setTransactionType] = useState(undefined);
  const [nature, setNature] = useState(undefined);
  const [executionDate, setExecutionDate] = useState(undefined);
  const [feeRate, setFeeRate] = useState(0);
  const [intermediateId, setIntermediateId] = useState(undefined);
  const [intermediateAccountId, setIntermediateAccountId] = useState(undefined);
  const [residence, setResidence] = useState(undefined);
  const [project, setProject] = useState(undefined);

  // Update only
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    fetchTransactionTypeList();
    if (transaction) {
      setAmount(transaction.amount);
      setTransactionType(transaction.transaction_type);
      setNature(transaction.nature);
      setExecutionDate(new Date(transaction.execution_date));
      setStatus(transaction.status);
      setFeeRate(transaction.fee_rate ? transaction.fee_rate * 100 : undefined);
      setIntermediateId(transaction.intermediate?.id);
      setIntermediateAccountId(transaction.intermediate_account?.id);
      setProject(transaction.project);
      setResidence(transaction.residence);
      setSelectedScope(getScopeFromId(transaction.linked_scope));
    } else {
      setAmount(0);
      setNature(CONSTANTS.TRANSACTION_NATURE_TYPES[0].value);
      setTransactionTypeList(undefined);
      setExecutionDate(new Date());
      setFeeRate(0);
      setStatus(
        CONSTANTS.TRANSACTION_STATUS.find((ts) => ts.value === "pending").value
      );
      setIntermediateId(investor?.intermediate?.id);
      setIntermediateAccountId(undefined);
      setProject(undefined);
      setResidence(undefined);
      setSelectedScope(
        getAuthorizedScopeListFromIds(investor?.linked_scopes)[0]
      );
    }
  }, [transaction]);

  useEffect(() => {
    fetchIntermediateList();
    fetchResidenceList();
    fetchProjectList();
  }, []);

  const fetchIntermediateList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/autocomplete/`
    ).then((res) => {
      if (res) setIntermediateList(res);
    });
  };

  const fetchResidenceList = async () => {
    await privateFetch("GET", `/cu/${accountContext.id}/residence/`).then(
      (res) => {
        if (res) setResidenceList(res);
      }
    );
  };

  const fetchProjectList = async () => {
    await privateFetch("GET", `/cu/${accountContext.id}/project/`).then(
      (res) => {
        if (res) setProjectList(res);
      }
    );
  };

  const fetchTransactionTypeList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/transaction/type/`
    ).then((res) => {
      setTransactionTypeList(res);
    });
  };

  const currentScope = useMemo(
    () =>
      transaction ? getScopeFromId(transaction.linked_scope) : selectedScope,
    [transaction, selectedScope]
  );

  const onValidate = () => {
    const transactionForm = {
      amount,
      transaction_type: transactionType.id,
      nature,
      execution_date: moment(executionDate).format("YYYY-MM-DD"),
      fee_rate: feeRate / 100,
      status,
      intermediate: intermediateId,
      intermediate_account: intermediateAccountId,
      linked_scope: selectedScope.id,
    };

    if (currentScope.name === "residens") {
      transactionForm["residence"] = residence?.id;
      transactionForm["project"] = undefined;
    } else {
      transactionForm["project"] = project?.id;
      transactionForm["residence"] = undefined;
    }

    transaction
      ? handleUpdate(transactionForm)
      : handleCreation(transactionForm);
  };

  const computeShareNumber = () => {
    if (!amount) return undefined;
    if (!transactionType) return undefined;
    if (!transactionType.share_price) return undefined;
    return amount / transactionType.share_price;
  };

  const projectTemplate = (option, props) => {
    if (option) {
      return (
        <span>
          <span className="mr-2 font-semibold">{option.reference} </span>
          {option.beneficiary.first_name} {option.beneficiary.name}
        </span>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex items-center gap-x-3 px-1">
          <div className={`h-2 w-2 rounded-full ${option.color}`} />
          <div className="font-medium">{option.label}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const intermediateTemplate = (item, props) => {
    if (!item) {
      return (
        <div className="row-gap">
          <span className="autocomplete-new-item">{props.placeholder}</span>
        </div>
      );
    } else {
      return (
        <div className="autocomplete-item-container">
          <Tag key={item.id} value={item.name} />
        </div>
      );
    }
  };

  const intermediateAccountTemplate = (item, props) => {
    if (!item) {
      return (
        <div className="row-gap">
          <span className="autocomplete-new-item">{props.placeholder}</span>
        </div>
      );
    } else {
      return (
        <div className="autocomplete-item-container">
          <Tag key={item.id} value={item.alias} />
        </div>
      );
    }
  };

  const amountIsValid = amount !== undefined;
  const executionDateIsValid = executionDate !== undefined;
  const typeIsValid = transactionType !== undefined;
  const natureIsValid = nature !== undefined;
  const scopeIsValid = selectedScope;
  const formIsValid =
    amountIsValid &&
    executionDateIsValid &&
    typeIsValid &&
    natureIsValid &&
    scopeIsValid;

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={"validate"}
        label={`Valider ${transaction ? "la modification" : "l'ajout"}`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );
  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${transaction ? `Modifier une transaction` : "Ajouter une transaction"}`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          {!isIntermediateAccount && (
            <FormInput label={"Nature"} required isValid={natureIsValid}>
              <div className="flex flex-row gap-x-5">
                {CONSTANTS.TRANSACTION_NATURE_TYPES.map((nt, i) => (
                  <div key={i} className="flex flex-row gap-x-2">
                    <RadioButton
                      inputId={nt.value}
                      name={nt.label}
                      value={nt.value}
                      onChange={(e) => setNature(e.target.value)}
                      checked={nature === nt.value}
                    />
                    <label htmlFor={nt.value}>{nt.label}</label>
                  </div>
                ))}
              </div>
            </FormInput>
          )}

          <FormInput label={"Périmètre"} required isValid={scopeIsValid}>
            <SelectButton
              value={selectedScope}
              onChange={(e) => setSelectedScope(e.value)}
              options={getAuthorizedScopeListFromIds(investor?.linked_scopes)}
              optionLabel="label"
              allowEmpty={false}
            />
          </FormInput>

          <FormInput label={"Type"} required isValid={typeIsValid}>
            <Dropdown
              value={transactionType}
              onChange={(e) => setTransactionType(e.value)}
              options={transactionTypeList}
              optionLabel="label"
              placeholder="Sélectionner un type de transaction"
            />
          </FormInput>
          {!isIntermediateAccount && (
            <FormInput label={"Intermediaire"}>
              <Dropdown
                id="intermediate"
                value={intermediateId}
                onChange={(e) => {
                  setIntermediateId(e.value);
                  setIntermediateAccountId(undefined);
                }}
                options={intermediateList}
                itemTemplate={intermediateTemplate}
                valueTemplate={intermediateTemplate}
                optionValue="id"
                optionLabel="name"
                placeholder="Veuillez sélectionner l'intermédiaire"
                showClear={true}
              />
            </FormInput>
          )}

          {!isIntermediateAccount && intermediateId && (
            <FormInput label={"Compte intermédiaire"}>
              <Dropdown
                id="intermediateAccount"
                value={intermediateAccountId}
                onChange={(e) => setIntermediateAccountId(e.value)}
                options={
                  intermediateList.find((i) => i.id === intermediateId)
                    ?.accounts
                }
                itemTemplate={intermediateAccountTemplate}
                valueTemplate={intermediateAccountTemplate}
                optionValue="id"
                optionLabel="alias"
                placeholder="Veuillez sélectionner le compte intermédiaire"
                showClear={true}
              />
            </FormInput>
          )}

          {!isIntermediateAccount && (
            <FormInput label={"Statut"}>
              <Dropdown
                value={status}
                onChange={(e) => setStatus(e.value)}
                options={CONSTANTS.TRANSACTION_STATUS}
                optionLabel="label"
                valueTemplate={dropdownTemplate}
                itemTemplate={dropdownTemplate}
              />
            </FormInput>
          )}

          {!isIntermediateAccount && currentScope?.name === "terrafine" && (
            <FormInput label={"Projet Terrafine lié"}>
              <Dropdown
                id="project"
                value={project}
                onChange={(e) => {
                  setProject(e.value);
                }}
                options={projectList}
                valueTemplate={projectTemplate}
                itemTemplate={projectTemplate}
                placeholder="Veuillez sélectionner un projet Terrafine"
                showClear={true}
              />
            </FormInput>
          )}

          {!isIntermediateAccount && currentScope?.name === "residens" && (
            <FormInput label={"Résidence Résid'ens liée"}>
              <Dropdown
                id="residence"
                value={residence}
                onChange={(e) => {
                  setResidence(e.value);
                }}
                options={residenceList}
                optionLabel="name"
                placeholder="Veuillez sélectionner une Résidence Résid'ens"
                showClear={true}
              />
            </FormInput>
          )}

          <FormInput label={"Montant"} required isValid={amountIsValid}>
            <InputNumber
              id="amount"
              value={amount}
              placeholder="Veuillez saisir le montant de la transaction"
              onValueChange={(e) => setAmount(e.value)}
              min={0}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput
            label={"Frais (en %)"}
            annotation={
              investor?.intermediate?.max_fee_rate
                ? `Max ${investor.intermediate.max_fee_rate * 100}%`
                : undefined
            }
          >
            <InputNumber
              value={feeRate}
              placeholder="Veuillez saisir le taux"
              onValueChange={(e) => setFeeRate(e.value)}
              minFractionDigits={1}
              maxFractionDigits={1}
              min={0}
              max={investor?.intermediate?.max_fee_rate * 100 || 100}
              suffix=" %"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput
            label={"Date d'éxecution"}
            required
            isValid={executionDateIsValid}
          >
            <Calendar
              id="execution-date"
              onChange={(e) => setExecutionDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date d'éxecution"
              maxDate={new Date()}
              value={executionDate}
              locale="fr"
            />{" "}
          </FormInput>
          <h4 className="mt-3">Champs calculés automatiquement</h4>
          <FormInput label={"Frais (en €)"}>
            <InputNumber
              id="feeAmount"
              value={feeRate ? (amount * feeRate) / 100 : undefined}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
              placeholder="Taux non renseigné"
              readOnly
            />
          </FormInput>
          <FormInput label={"Nombre de parts"}>
            <InputNumber
              id="shareNb"
              value={computeShareNumber()}
              placeholder='Pas de calcul de part pour "Compte Courant"'
              readOnly
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageTransaction;

import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import FormInput from "../../components/Form/FormInput";
import { Dropdown } from "primereact/dropdown";
import slugify from "react-slugify";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { useAccountContext } from "../../contexts/AccountContext";

function ManageDocument({
  isOpen,
  handleClose,
  uploadDocument,
  updateDocument,
  deleteDocument,
  loading,
  document,
  options,
  defaultType = "other",
  mandatoryType,
}) {
  const { isIntermediateAccount, isInvestorAccount } = useAccountContext();
  const fileUploadRef = useRef(null);
  const [fileName, setFileName] = useState(undefined);

  // Mandatory
  const [name, setName] = useState("");
  const [file, setFile] = useState(undefined);
  const [type, setType] = useState(undefined);
  const [filePath, setFilePath] = useState("");

  useEffect(() => {
    if (document) {
      setName(document.name);
      setFilePath(document.file);
      setType(document.type);
    } else {
      setName("");
      setFile(undefined);
      setFileName(undefined);
      setType(mandatoryType || defaultType);
      setFilePath(undefined);
      fileUploadRef.current && fileUploadRef.current.clear();
    }
  }, [document, isOpen]);

  const onValidate = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    // Update case only
    document && formData.append("id", document.id);
    // If file has changed
    file && formData.append("file", file);
    document ? updateDocument(formData) : uploadDocument(formData);
  };

  const onUpload = (event) => {
    if (event.files.length === 1) {
      const file = event.files[0];
      setFilePath(file.objectURL);
      setFile(file);
      setFileName(file.name);
      setName(slugify(file.name));
    }
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    setFileName(undefined);
    setFilePath(undefined);
    fileUploadRef.current.clear();
  };

  const handleOpenDocument = () => {
    window.open(filePath, "_blank");
  };

  const formIsValid = name.length && filePath;

  const footerButtons = (
    <div className="manage-footer">
      {deleteDocument && document && (
        <Button
          key={"delete"}
          label={`Supprimer le document`}
          onClick={deleteDocument}
          severity="danger"
          outlined
        />
      )}
      {(updateDocument || !document) && (
        <Button
          key={"validate"}
          label={`${document ? (document.validated ? "Modifier" : "Valider") : "Ajouter"} le document`}
          loading={loading}
          disabled={!formIsValid}
          onClick={onValidate}
          severity="success"
        />
      )}
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${document ? `Modifier un document` : "Ajouter un document"}`}
        />
      }
      showCloseIcon={true}
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      {/* TODO : ADD SubAppSelector (voir ManageInvestor pour ref) */}
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          {document && (
            <FormInput label={"Voir le document"}>
              <div style={{ width: "fit-content" }}>
                <Button
                  label={name}
                  icon="pi pi-eye"
                  onClick={handleOpenDocument}
                />
              </div>
            </FormInput>
          )}
          {options && (
            <FormInput label={"Type de document"}>
              <Dropdown
                value={type}
                onChange={(e) => setType(e.value)}
                options={options}
                optionLabel="label"
                optionValue="type"
                readOnly={mandatoryType}
                disabled={mandatoryType}
              />
            </FormInput>
          )}

          <FormInput label={"Nouveau fichier"}>
            {fileName && (
              <div className="flex gap-x-3 text-custom-green font-medium items-center mb-2 mt-1">
                <span className="pi pi-check"></span>
                <span>{fileName}</span>
              </div>
            )}
            <div className="row-gap">
              {file && (
                <Button
                  key={"delete-img"}
                  label={"Changer de fichier"}
                  outlined
                  disabled={!updateDocument}
                  onClick={handleRemoveFile}
                  severity="primary"
                />
              )}
              <FileUpload
                ref={fileUploadRef}
                auto
                disabled={file || !updateDocument}
                mode="basic"
                name="file"
                accept="*"
                maxFileSize={CONSTANTS.maxImageSize}
                customUpload
                uploadHandler={onUpload}
                chooseLabel="Depuis mon ordinateur"
              />
            </div>
          </FormInput>

          <FormInput label={"Nom du fichier"} required isValid={name.length}>
            <InputText
              id="name"
              value={name}
              disabled={
                (isIntermediateAccount || isInvestorAccount) && document
              }
              placeholder="Veuillez saisir le nom du document"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageDocument;

import React from "react";
import { MEDIAS } from "../../ressources/MEDIAS";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Data from "../../components/Display/Data";
import DataTag from "../../components/Display/DataTag";
import DocumentList from "../../components/DocumentList";
import Section from "../../components/Display/Section";
import { useNavigationContext } from "../../contexts/NavigationContext";
import { useAccountContext } from "../../contexts/AccountContext";

export const TransactionDetail = ({
  transaction,
  handleUpdateTransaction,
  handleUploadDocument,
  handleUpdateDocument,
}) => {
  const { getScopeFromId } = useAuthorizationsContext();
  const { navigateToIntermediate } = useNavigationContext();
  const { isAdminAccount, isIntermediateAccount } = useAccountContext();

  const handleExistingDocument = (d) => {
    handleUpdateDocument(d, d.type);
  };

  const handleMissingDocument = (d) => {
    handleUpdateDocument(undefined, d.type);
  };

  // Intermediates accounts can only update pending transactions
  const informationActions = (!isIntermediateAccount ||
    transaction?.status === "pending") &&
    handleUpdateTransaction && [
      {
        onClick: handleUpdateTransaction,
        label: "Modifier les informations",
        icon: "pi-pencil",
      },
    ];

  const documentActions = handleUploadDocument && [
    {
      onClick: handleUploadDocument,
      label: "Ajouter un document",
      icon: "pi-plus",
    },
  ];

  if (!transaction) return null;

  return (
    <div className="main-content-container ">
      <Section label="Informations" actions={informationActions}>
        <div className="data-section-row">
          <Data
            label={"Investisseur"}
            value={`${transaction.investor.first_name} ${transaction.investor.name}`}
            large
          />
          <DataTag
            label={"Périmètre"}
            value={getScopeFromId(transaction.linked_scope).label}
            bgColor={
              "bg-" + getScopeFromId(transaction.linked_scope).main_color
            }
            rounded
          />
          <DataTag
            label={"Statut"}
            value={
              CONSTANTS.TRANSACTION_STATUS.find(
                (ts) => ts.value === transaction.status
              ).label
            }
            bgColor={
              CONSTANTS.TRANSACTION_STATUS.find(
                (ts) => ts.value === transaction.status
              ).color
            }
            rounded
          />
          <DataTag
            label={"Nature"}
            value={
              CONSTANTS.TRANSACTION_NATURE_TYPES.find(
                (nt) => nt.value === transaction.nature
              ).label
            }
            bgColor={
              CONSTANTS.TRANSACTION_NATURE_TYPES.find(
                (nt) => nt.value === transaction.nature
              ).color
            }
            rounded
          />
          <DataTag
            label={"Type"}
            value={transaction.transaction_type.label}
            large
            rounded
          />
          {getScopeFromId(transaction.linked_scope).name === "residens" && (
            <Data
              label={"Résidence liée"}
              value={transaction.residence?.name}
              large
            />
          )}
          {getScopeFromId(transaction.linked_scope).name === "terrafine" && (
            <Data
              label={"Projet lié"}
              value={
                transaction.project &&
                `${transaction.project?.reference} - ${transaction.project?.beneficiary?.first_name} ${transaction.project?.beneficiary?.name}`
              }
              large
            />
          )}

          <Data
            label={"Date d'éxecution"}
            value={transaction.execution_date}
            type={"date"}
          />
          <Data label={"ID"} value={transaction.id} />
        </div>

        {!isIntermediateAccount && (
          <div className="data-section-row">
            <DataTag
              label={"Intermédiaire"}
              value={transaction.intermediate?.name || ""}
              icon={MEDIAS.briefcaseWhite}
              onClick={() =>
                isAdminAccount &&
                navigateToIntermediate(transaction.intermediate)
              }
              large
            />
            <DataTag
              icon={MEDIAS.userWhite}
              label={"Compte intermédiaire"}
              value={transaction.intermediate_account?.alias || ""}
              onClick={() =>
                isAdminAccount &&
                navigateToIntermediate(transaction.intermediate)
              }
              large
            />
          </div>
        )}

        <div className="data-section-row">
          <Data
            label={"Montant"}
            value={transaction.amount}
            type={"currency"}
          />
          <Data label={"Nb de parts"} value={transaction.share_nb} />
          <Data
            label={"Frais (en %)"}
            value={transaction.fee_rate}
            type={"percentage"}
          />
          <Data
            label={"Frais (en €)"}
            value={
              transaction.fee_rate !== null
                ? transaction.amount * transaction.fee_rate
                : undefined
            }
            type={"currency"}
          />
        </div>
      </Section>

      <Section label="Documents" actions={documentActions}>
        <DocumentList
          handleMissingDocument={handleMissingDocument}
          handleExistingDocument={handleExistingDocument}
          requiredDocuments={
            CONSTANTS.TRANSACTION_DOCUMENTS[transaction.transaction_type.type]
          }
          documents={transaction.documents}
        />
      </Section>
    </div>
  );
};

import React, { useState, useEffect, useMemo } from "react";
import { privateFetch } from "../../../utils/apiHelper";
import { InvestorDatatable } from "../../../modules/investor/InvestorDatatable";
import { InvestorDetail } from "../../../modules/investor/InvestorDetail";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import { confirmDialog } from "primereact/confirmdialog";
import ManageInvestor from "../../../modules/managers/ManageInvestor";
import ManageDocument from "../../../modules/managers/ManageDocument";
import ManageTransaction from "../../../modules/managers/ManageTransaction";
import ManageReport from "../../../modules/managers/ManageReport";
import { formatDate, generateYearList } from "../../../utils/dateHelper";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import "../../Pages.scss";
import { useDebouncedCallback } from "use-debounce";
import { TransactionDetail } from "../../../modules/transaction/TransactionDetail";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import { useInvestorContext } from "../../../contexts/InvestorContext";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import slugify from "react-slugify";
import CommentFeed from "../../../components/Comment/CommentFeed";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import ManageComment from "../../../components/Comment/ManageComment";

function InvestorsPage({ hidden, showComment }) {
  const {
    authorizationsContext,
    getSubappAuthorizationType,
    getAuthorizedScopeList,
    getScopeFromId,
  } = useAuthorizationsContext();
  const { investorContext, handleSelectedInvestor, handleSelectedTransaction } =
    useInvestorContext();
  const { accountContext, isIntermediateAccount } = useAccountContext();
  const { navigationContext, handleInvestorView } = useNavigationContext();

  const [intermediateList, setIntermediateList] = useState(undefined);
  const [allInvestorList, setAllInvestorList] = useState([]);
  const [investorList, setInvestorList] = useState([]);
  const [investor, setInvestor] = useState(null);
  const [investorModuleOpen, setInvestorModuleOpen] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [transactionModuleOpen, setTransactionModuleOpen] = useState(false);
  const [customDocument, setDocument] = useState(null);
  const [documentType, setDocumentType] = useState(undefined);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);
  const [transactionDocument, setTransactionDocument] = useState(null);
  const [transactionDocumentModuleOpen, setTransactionDocumentModuleOpen] =
    useState(false);
  const [transactionDocumentType, setTransactionDocumentType] =
    useState(undefined);
  const [loading, setLoading] = useState(false);
  const [datatableLoading, setDatatableLoading] = useState(true);

  const [commentManagerIsOpen, setCommentManagerIsOpen] = useState(false);
  const [commentList, setCommentList] = useState(undefined);
  useEffect(() => {
    fetchIntermediateList();
  }, []);

  // Generated documents
  const [documentGenerationManagerOpen, setDocumentGenerationManagerOpen] =
    useState(false);
  const [documentGenerationLoading, setDocumentGenerationLoading] =
    useState(false);
  const [documentGenerationInvestor, setDocumentGenerationInvestor] =
    useState(undefined);

  useEffect(() => {
    fetchAllInvestorList();
  }, []);

  useEffect(() => {
    if (investor) {
      fetchCommentList(investor);
    }
  }, [investor]);

  useEffect(() => {
    refreshInverstorList();
  }, [investorContext]);

  useEffect(() => {
    investorContext?.selectedInvestorId && fetchInvestorDetail();
  }, [investorContext?.selectedInvestorId]);

  useEffect(() => {
    if (
      investorContext.selectedInvestorId &&
      investorContext.selectedTransactionId
    ) {
      fetchTransactionDetail();
    } else {
      setTransaction(undefined);
    }
  }, [investorContext?.selectedTransactionId]);

  const accountHasWriteAuthOnInvestor = useMemo(() => {
    if (isIntermediateAccount) return true;
    // If User has READ_WRITE auth on the INVESTOR Subapp for at least one scope
    return getAuthorizedScopeList().some(
      (s) => getSubappAuthorizationType(s.name, "INVESTOR") === "READ_WRITE"
    );
  }, [authorizationsContext]);

  const isTransactionReadWrite = useMemo(() => {
    if (!transaction) return false;
    // If User has READ_WRITE auth on the INVESTOR Subapp transaction scope
    return (
      getSubappAuthorizationType(
        getScopeFromId(transaction.linked_scope).name,
        "INVESTOR"
      ) === "READ_WRITE"
    );
  }, [authorizationsContext, transaction]);

  const isInvestorDocumentReadWrite = useMemo(() => {
    // If current user is Intermediate and document is validated, it can't be edited
    if (customDocument && isIntermediateAccount && customDocument.validated)
      return false;
    return accountHasWriteAuthOnInvestor;
  }, [authorizationsContext, customDocument]);

  const isTransactionDocumentReadWrite = useMemo(() => {
    // If current user is Intermediate and transaction document is validated, it can't be edited
    if (
      transactionDocument &&
      isIntermediateAccount &&
      transactionDocument.validated &&
      transactionDocumentType
    )
      return false;
    return isInvestorDocumentReadWrite;
  }, [authorizationsContext, transactionDocument, transactionDocumentType]);

  const fetchAllInvestorList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/investor/autocomplete/`
    ).then((res) => {
      if (res) setAllInvestorList(res);
    });
  };

  const fetchIntermediateList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/autocomplete/`
    ).then((res) => {
      if (res) setIntermediateList(res);
    });
  };

  const refreshInverstorList = useDebouncedCallback(() => {
    fetchInvestorList();
  }, 500);

  const fetchInvestorList = async () => {
    setDatatableLoading(true);
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/investor/?rows=${investorContext.options.rows}&page=${
        investorContext.options.page
      }&offset=${investorContext.options.offset}&query=${slugify(
        investorContext.filters.query
      )}&linked_scopes=${investorContext.filters.scopeList.map(
        (s) => s.id
      )}&intermediates=${investorContext.filters.intermediateList.map(
        (i) => i.id
      )}&sort_field=${investorContext.sortOption.sortField}&sort_order=${investorContext.sortOption.sortOrder}`
    ).then((res) => {
      if (res) {
        setInvestorList(res);
        setDatatableLoading(false);
      }
    });
  };

  const createInvestorAccount = async () => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/account/`,
      null,
      "Le compte utilisateur a bien été créé.",
      JSON.stringify({
        email: investor.email,
      })
    ).then((response) => {
      if (response) {
        setLoading(false);
        fetchIntermediateList();
        fetchInvestorDetail(investor);
      }
    });
  };

  const deleteInvestorAccount = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/cu/${investor.account.id}/`,
      "Une erreur est survenue lors de la suppression du compte investisseur",
      "Le compte investisseur a bien été supprimé."
    ).then((res) => {
      fetchIntermediateList();
      fetchInvestorDetail(investor);
      setLoading(false);
    });
  };

  const fetchInvestorDetail = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/investor/${investorContext?.selectedInvestorId}/`
    ).then((res) => {
      setInvestor(res);
    });
  };

  const handleSelectInvestor = (targetInvestor) => {
    handleSelectedInvestor(targetInvestor);
    handleInvestorView("INVESTOR");
  };

  const handleCreateInvestor = () => {
    setInvestor(undefined);
    setInvestorModuleOpen(true);
  };

  const handleUpdateInvestor = () => {
    setInvestorModuleOpen(true);
  };

  const confirmInvestorAccountCreation = () => {
    if (!investor.email) {
      window.PrimeToast.show({
        severity: "error",
        summary:
          "Impossible de créer un compte utilisateur. Merci de renseigner une adresse mail valide dans les informations générales de l'investisseur.",
      });
    } else {
      confirmDialog({
        message: `Êtes-vous sûr de créer un compte pour l'investisseur ${investor.first_name} ${investor.name} ? Un mail sera envoyé à ${investor.email} afin de choisir un mot de passe. `,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Oui",
        rejectLabel: "Annuler",
        accept: createInvestorAccount,
      });
    }
  };

  const createInvestor = async (investorForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/`,
      "Une erreur est survenue lors de la création de l'investisseur",
      "L'investisseur a bien été créé.",
      JSON.stringify(investorForm)
    ).then((response) => {
      setLoading(false);
      setInvestorModuleOpen(false);
      fetchInvestorList();
      if (response) {
        handleSelectedInvestor(response);
        handleInvestorView("INVESTOR");
      }
    });
  };

  const updateInvestor = async (investorForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/investor/${investor.id}/`,
      "Une erreur est survenue lors de la modification de l'investisseur",
      "L'investisseur a bien été modifié.",
      JSON.stringify(investorForm)
    ).then(() => {
      setLoading(false);
      setInvestorModuleOpen(false);
      fetchInvestorList();
      fetchInvestorDetail(investor);
    });
  };

  const handleUploadInvestorDocument = () => {
    setDocument(undefined);
    setDocumentModuleOpen(true);
    setDocumentType(undefined);
  };

  const handleUpdateInvestorDocument = (document, mandatoryType) => {
    setDocument(document);
    setDocumentModuleOpen(true);
    setDocumentType(mandatoryType);
  };

  const handleCreateTransaction = () => {
    setTransaction(undefined);
    setTransactionModuleOpen(true);
  };

  const handleUpdateTransaction = () => {
    setTransactionModuleOpen(true);
  };

  const handleUploadTransactionDocument = () => {
    setTransactionDocumentModuleOpen(true);
    setTransactionDocument(undefined);
    setTransactionDocumentType(undefined);
  };

  const handleUpdateTransactionDocument = (document, mandatoryType) => {
    setTransactionDocument(document);
    setTransactionDocumentType(mandatoryType);
    setTransactionDocumentModuleOpen(true);
  };

  const fetchTransactionDetail = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/investor/${investorContext?.selectedInvestorId}/transaction/${investorContext.selectedTransactionId}/`
    ).then((res) => {
      setTransaction(res);
    });
  };

  const createTransaction = async (transactionForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/transaction/`,
      "Une erreur est survenue lors de la création de la transaction.",
      "La transaction a bien été ajoutée.",
      JSON.stringify(transactionForm)
    ).then((res) => {
      setLoading(false);
      setTransactionModuleOpen(false);
      fetchInvestorList();
      fetchInvestorDetail();
      handleSelectedTransaction(res);
      handleInvestorView("TRANSACTION");
    });
  };

  const updateTransaction = async (transactionForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/investor/${investor.id}/transaction/${transaction.id}/`,
      "Une erreur est survenue lors de la modification de la transaction.",
      "La transaction a bien été modifiée.",
      JSON.stringify(transactionForm)
    ).then(() => {
      setLoading(false);
      setTransactionModuleOpen(false);
      fetchInvestorList();
      fetchInvestorDetail();
      fetchTransactionDetail();
    });
  };

  const handleTransactionSelection = (transaction) => {
    handleSelectedTransaction(transaction);
    handleInvestorView("TRANSACTION");
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchInvestorDetail();
    });
  };

  const uploadTransactionDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/transaction/${transaction.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setTransactionDocumentModuleOpen(false);
      fetchInvestorDetail();
      fetchTransactionDetail();
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${customDocument.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocument(undefined);
      setDocumentModuleOpen(false);
      setTransactionDocumentModuleOpen(false);
      fetchInvestorDetail();
    });
  };

  const updateTransactionDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${transactionDocument.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setTransactionDocument(undefined);
      setTransactionDocumentModuleOpen(false);
      fetchInvestorDetail();
      fetchTransactionDetail();
    });
  };

  const handleDownloadDocument = (document) => {
    window.open(document.file, "_blank");
  };

  const deleteDocument = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${customDocument.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then(() => {
      setLoading(false);
      setDocument(undefined);
      setDocumentModuleOpen(false);
      setTransactionDocumentModuleOpen(false);
      fetchInvestorDetail();
    });
  };

  const deleteTransactionDocument = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${transactionDocument.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then(() => {
      setLoading(false);
      setTransactionDocument(undefined);
      setTransactionDocumentModuleOpen(false);
      fetchInvestorDetail();
      fetchTransactionDetail();
    });
  };

  const createComment = async (commentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/comment/`,
      "Une erreur est survenue lors de l'ajout de commentaire.",
      `Le commentaire a bien été créé.`,
      JSON.stringify(commentForm)
    ).then(() => {
      setLoading(false);
      setCommentManagerIsOpen(false);
      fetchInvestorDetail();
    });
  };

  const fetchCommentList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/investor/${investor.id}/comment/`
    ).then((res) => {
      setCommentList(res);
    });
  };

  const handleOpenCommentManager = () => {
    setCommentManagerIsOpen(true);
  };

  const handleGenerateDocument = (investor) => {
    setDocumentGenerationManagerOpen(true);
    setDocumentGenerationInvestor(investor || undefined);
  };

  const openDownloadedDocument = async (response) => {
    if (response) {
      const blob = await response.blob();
      try {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
      } catch (e) {
        console.log(e);
      }
    }
  };

  const generateDocument = async (
    type,
    investor,
    yearList,
    selectedScope,
    toDownload
  ) => {
    return await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/${type}/`,
      `Une erreur est survenue lors de la génération du document pour l'investisseur ${investor.name}`,
      null,
      JSON.stringify({
        year_list: yearList,
        to_download: toDownload,
        scope: selectedScope?.id,
      }),
      true
    ).then((response) => {
      if (toDownload) {
        openDownloadedDocument(response);
      }
      return response;
    });
  };

  const handleDocumentGeneration = async (payload) => {
    setDocumentGenerationLoading(true);

    let success = true;
    const yearList = payload.selectedYear
      ? [payload.selectedYear]
      : generateYearList(2016);
    if (payload.selectedInvestor) {
      const response = await generateDocument(
        payload.type,
        payload.selectedInvestor,
        yearList,
        payload.selectedScope,
        payload.toDownload
      );
      if (!response) success = false;
    } else {
      await Promise.all(
        allInvestorList.map(async (investor) => {
          const response = await generateDocument(
            payload.type,
            investor,
            yearList,
            payload.selectedScope,
            payload.toDownload
          );
          if (!response) success = false;
        })
      );
    }
    setDocumentGenerationManagerOpen(false);
    window.PrimeToast.show({
      severity: "success",
      summary: "L'opération s'est bien déroulée !",
    });
    setTimeout(() => {
      setDocumentGenerationLoading(false);
    }, 500);
  };

  const confirmDocumentDeletion = () => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${customDocument.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: deleteDocument,
    });
  };

  const confirmInvestorAccountDeletion = () => {
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le compte investisseur ${investor.account.alias} ?`,
      header: "Action irrévesrible",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteInvestorAccount(investor.account),
    });
  };

  const confirmTransactionDocumentDeletion = () => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${transactionDocument.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: deleteTransactionDocument,
    });
  };

  const breadcrumbItems = () => {
    const items = [
      {
        label: "Investisseurs",
        onClick: () => handleInvestorView("DATATABLE"),
        isActive: navigationContext?.investorView === "DATATABLE",
      },
    ];
    // Investor view
    investor &&
      items.push({
        label: `${investor.name} ${investor.first_name}`,
        onClick: () => handleInvestorView("INVESTOR"),
        isActive: navigationContext?.investorView === "INVESTOR",
      });
    // Transaction view
    transaction &&
      items.push({
        label: `${transaction.transaction_type.ref} - ${formatDate(transaction.execution_date)}`,
        onClick: () => handleInvestorView("TRANSACTION"),
        isActive: navigationContext?.investorView === "TRANSACTION",
      });
    return items;
  };

  const mainContent = () => {
    switch (navigationContext?.investorView) {
      case "DATATABLE":
        return (
          <div className="p-4 w-full overflow-y-hidden">
            <div className="full-page-datatable">
              <InvestorDatatable
                loading={datatableLoading}
                data={investorList}
                selectItem={handleSelectInvestor}
                intermediateList={intermediateList}
                handleCreateInvestor={
                  accountHasWriteAuthOnInvestor && handleCreateInvestor
                }
                handleGenerateDocument={
                  accountHasWriteAuthOnInvestor && handleGenerateDocument
                }
              />
            </div>
          </div>
        );
      case "INVESTOR":
        return (
          <div className="flex flex-row h-full">
            <div
              className={`${showComment ? "w-3/4" : "w-full"} h-auto overflow-y-scroll`}
            >
              <InvestorDetail
                investor={investor}
                documentList={investor?.documents || []}
                selectTransaction={handleTransactionSelection}
                handleInvestorUpdate={
                  accountHasWriteAuthOnInvestor && handleUpdateInvestor
                }
                handleCreateTransaction={
                  accountHasWriteAuthOnInvestor && handleCreateTransaction
                }
                handleUploadDocument={
                  accountHasWriteAuthOnInvestor && handleUploadInvestorDocument
                }
                handleUpdateDocument={
                  accountHasWriteAuthOnInvestor
                    ? handleUpdateInvestorDocument
                    : handleDownloadDocument
                }
                handleCreateAccount={
                  accountHasWriteAuthOnInvestor &&
                  confirmInvestorAccountCreation
                }
                handleDeleteAccount={
                  accountHasWriteAuthOnInvestor &&
                  confirmInvestorAccountDeletion
                }
                handleGenerateDocument={handleGenerateDocument}
                accountHasWriteAuthOnInvestor={accountHasWriteAuthOnInvestor}
              />
            </div>
            {showComment && (
              <div className="w-1/4 h-full overflow-y-scroll">
                <CommentFeed
                  commentList={commentList}
                  handleCreateComment={handleOpenCommentManager}
                />
              </div>
            )}
          </div>
        );
      case "TRANSACTION":
        return (
          <div className="flex flex-row h-full">
            <div
              className={`${showComment ? "w-3/4" : "w-full"} h-auto overflow-y-scroll`}
            >
              <TransactionDetail
                transaction={transaction}
                handleUpdateTransaction={
                  isTransactionReadWrite && handleUpdateTransaction
                }
                handleUploadDocument={
                  isTransactionReadWrite && handleUploadTransactionDocument
                }
                handleUpdateDocument={
                  isTransactionReadWrite
                    ? handleUpdateTransactionDocument
                    : handleDownloadDocument
                }
              />
            </div>
            {showComment && (
              <div className="w-1/4 h-full overflow-y-scroll">
                <CommentFeed
                  commentList={commentList}
                  handleCreateComment={handleOpenCommentManager}
                />
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Header */}
      <BreadCrumb items={breadcrumbItems()} />
      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper">
        {mainContent()}
      </div>
      {/* Modules */}
      <ManageInvestor
        loading={loading}
        investor={investor}
        intermediateList={intermediateList}
        isOpen={investorModuleOpen}
        handleClose={() => setInvestorModuleOpen(false)}
        handleCreation={createInvestor}
        handleUpdate={updateInvestor}
      />

      <ManageDocument
        key={"investor"}
        loading={loading}
        document={customDocument}
        options={
          documentType
            ? CONSTANTS.INVESTOR_DOCUMENTS
            : CONSTANTS.INVESTOR_DOCUMENTS.filter((d) => !d.required)
        }
        mandatoryType={documentType}
        isOpen={documentModuleOpen}
        handleClose={() => {
          setDocumentModuleOpen(false);
          setDocument(undefined);
        }}
        uploadDocument={accountHasWriteAuthOnInvestor && uploadDocument}
        updateDocument={isInvestorDocumentReadWrite && updateDocument}
        deleteDocument={isInvestorDocumentReadWrite && confirmDocumentDeletion}
      />

      {transaction && (
        <ManageDocument
          key={"transaction"}
          loading={loading}
          document={transactionDocument}
          options={
            transactionDocumentType
              ? CONSTANTS.TRANSACTION_DOCUMENTS[
                  transaction.transaction_type.type
                ]
              : CONSTANTS.TRANSACTION_DOCUMENTS[
                  transaction.transaction_type.type
                ].filter((d) => !d.required)
          }
          mandatoryType={transactionDocumentType || "other"}
          isOpen={transactionDocumentModuleOpen}
          handleClose={() => {
            setTransactionDocumentModuleOpen(false);
            setTransactionDocument(undefined);
          }}
          uploadDocument={isTransactionReadWrite && uploadTransactionDocument}
          updateDocument={
            isTransactionDocumentReadWrite && updateTransactionDocument
          }
          deleteDocument={
            isTransactionDocumentReadWrite && confirmTransactionDocumentDeletion
          }
        />
      )}

      <ManageTransaction
        loading={loading}
        investor={investor}
        transaction={transaction}
        isOpen={transactionModuleOpen}
        handleClose={() => {
          setTransactionModuleOpen(false);
        }}
        handleCreation={createTransaction}
        handleUpdate={updateTransaction}
      />

      <ManageReport
        loading={loading}
        investor={documentGenerationInvestor}
        allInvestorList={allInvestorList}
        isOpen={documentGenerationManagerOpen}
        handleClose={() => setDocumentGenerationManagerOpen(false)}
        handleValidation={(payload) => {
          handleDocumentGeneration(payload, investor);
        }}
      />

      <ManageComment
        loading={loading}
        isOpen={commentManagerIsOpen}
        handleClose={() => {
          setCommentManagerIsOpen(false);
        }}
        createComment={createComment}
      />

      <Dialog
        header="Génération des documents en cours..."
        visible={documentGenerationLoading}
        style={{ width: "30vw" }}
        draggable={false}
        closable={false}
      >
        <div className="dialog-container">
          <div style={{ width: 30, margin: 15 }}>
            <ProgressSpinner style={{ width: "30px", height: "30px" }} />
          </div>
          <p>Merci de ne pas fermer la fenêtre</p>
        </div>
      </Dialog>
    </div>
  );
}

export default InvestorsPage;

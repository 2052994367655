import React, { useMemo } from "react";
import { DIMENSIONS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import { useNavigate } from "react-router-dom";
import InvestorsPage from "../common/InvestorsPage";
import IntermediatePage from "./IntermediatePage";
import DashboardPage from "./DashboardPage";
import ResidencePage from "./ResidencePage";
import BeneficiaryPage from "./BeneficiaryPage";
import UserPage from "./UserPage";
import ToolboxPage from "./ToolboxPage";
import AccountPage from "../common/AccountPage";
import ArchivePage from "../common/ArchivePage";
import NavIconButton from "../../../components/NavIconButton";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import { saveSession } from "../../../utils/sessionHelper";
import "../../Home.scss";
import "../../Pages.scss";
import "../../Datatables.scss";
import MenuTitle from "../../../components/MenuTitle";
import { Button } from "primereact/button";

function InternalRouter() {
  const { getSubappAuthorizationType } = useAuthorizationsContext();
  const {
    navigationContext,
    changeSubapp,
    showReleaseNote,
    showBugReport,
    setExpandedMenu,
  } = useNavigationContext();
  const { isAdminAccount } = useAccountContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    saveSession(null);
    navigate("/");
  };

  const showInvestorSubapp = useMemo(
    () =>
      ["READ_ONLY", "READ_WRITE"].includes(
        getSubappAuthorizationType("terrafine", "INVESTOR")
      ) ||
      ["READ_ONLY", "READ_WRITE"].includes(
        getSubappAuthorizationType("residens", "INVESTOR")
      )
  );

  const showIntermediateSubapp = useMemo(
    () =>
      ["READ_ONLY", "READ_WRITE"].includes(
        getSubappAuthorizationType("terrafine", "INTERMEDIATE")
      ) ||
      ["READ_ONLY", "READ_WRITE"].includes(
        getSubappAuthorizationType("residens", "INTERMEDIATE")
      )
  );

  // Terrafine
  const showProjectSubapp = useMemo(() =>
    ["READ_ONLY", "READ_WRITE"].includes(
      getSubappAuthorizationType("terrafine", "PROJECT")
    )
  );

  // Residens
  const showResidenceSubapp = useMemo(() =>
    ["READ_ONLY", "READ_WRITE"].includes(
      getSubappAuthorizationType("residens", "PROJECT")
    )
  );

  const subappMenu = () => (
    <div className="navbar-container">
      <div className="navbar-menu-container">
        <MenuTitle
          label={""}
          img={MEDIAS.logoCrescendo}
          showLabel={navigationContext.expandedMenu}
        />
        <div className="navbar-submenu-container">
          {isAdminAccount && (
            <NavIconButton
              key={"dashboard"}
              index={"dashboard"}
              text={"Dashboard"}
              src={MEDIAS.dashboard}
              onClick={() => changeSubapp("dashboard")}
              isActive={navigationContext.currentSubapp === "dashboard"}
              showLabel={navigationContext.expandedMenu}
            />
          )}
          {showInvestorSubapp && (
            <NavIconButton
              key={"investor"}
              index={"investor"}
              text={"Investisseurs"}
              src={MEDIAS.thirdParty}
              onClick={() => changeSubapp("investor")}
              isActive={navigationContext.currentSubapp === "investor"}
              showLabel={navigationContext.expandedMenu}
            />
          )}
          {showInvestorSubapp && (
            <NavIconButton
              key={"archive"}
              index={"archive"}
              text={"Transactions"}
              src={MEDIAS.archive}
              onClick={() => changeSubapp("archive")}
              isActive={navigationContext.currentSubapp === "archive"}
              showLabel={navigationContext.expandedMenu}
            />
          )}
          {showIntermediateSubapp && (
            <NavIconButton
              key={"intermediate"}
              index={"intermediate"}
              text={"Intermédiaires"}
              src={MEDIAS.briefcase}
              onClick={() => changeSubapp("intermediate")}
              isActive={navigationContext.currentSubapp === "intermediate"}
              showLabel={navigationContext.expandedMenu}
            />
          )}

          <NavIconButton
            key={"toolbox"}
            index={"toolbox"}
            text={"Boîte à outils"}
            src={MEDIAS.toolbox}
            onClick={() => changeSubapp("toolbox")}
            isActive={navigationContext.currentSubapp === "toolbox"}
            showLabel={navigationContext.expandedMenu}
          />
        </div>
        {(showProjectSubapp || showResidenceSubapp) && (
          <div className="navbar-submenu-container">
            {navigationContext.expandedMenu ? (
              <div className="navbar-submenu-label">Sous applications</div>
            ) : (
              <div className="navbar-submenu-separator" />
            )}
            {showProjectSubapp && (
              <NavIconButton
                key={"beneficiary"}
                index={"beneficiary"}
                text={"Terrafine"}
                src={MEDIAS.navIconProject}
                onClick={() => changeSubapp("beneficiary")}
                isActive={navigationContext.currentSubapp === "beneficiary"}
                showLabel={navigationContext.expandedMenu}
              />
            )}
            {showResidenceSubapp && (
              <NavIconButton
                key={"residence"}
                index={"residence"}
                text={"Résid'Ens"}
                src={MEDIAS.residence}
                onClick={() => changeSubapp("residence")}
                isActive={navigationContext.currentSubapp === "residence"}
                showLabel={navigationContext.expandedMenu}
              />
            )}
          </div>
        )}

        <div className="navbar-submenu-container">
          {navigationContext.expandedMenu ? (
            <div className="navbar-submenu-label">Paramètres</div>
          ) : (
            <div className="navbar-submenu-separator" />
          )}
          {isAdminAccount && (
            <NavIconButton
              key={"user"}
              index={"user"}
              text={"Utilisateurs"}
              src={MEDIAS.users}
              onClick={() => changeSubapp("user")}
              isActive={navigationContext.currentSubapp === "user"}
              showLabel={navigationContext.expandedMenu}
            />
          )}
          <NavIconButton
            key={"account"}
            index={"account"}
            text={"Mon compte"}
            src={MEDIAS.account}
            onClick={() => changeSubapp("account")}
            isActive={navigationContext.currentSubapp === "account"}
            showLabel={navigationContext.expandedMenu}
          />
        </div>
      </div>
    </div>
  );

  const subapp = (
    <>
      {isAdminAccount && (
        <DashboardPage
          hidden={navigationContext.currentSubapp !== "dashboard"}
        />
      )}
      {showInvestorSubapp && (
        <InvestorsPage
          hidden={navigationContext.currentSubapp !== "investor"}
          showComment
        />
      )}
      {showIntermediateSubapp && (
        <IntermediatePage
          hidden={navigationContext.currentSubapp !== "intermediate"}
        />
      )}
      <ToolboxPage hidden={navigationContext.currentSubapp !== "toolbox"} />
      {showResidenceSubapp && (
        <ResidencePage
          hidden={navigationContext.currentSubapp !== "residence"}
        />
      )}
      {showProjectSubapp && (
        <BeneficiaryPage
          hidden={navigationContext.currentSubapp !== "beneficiary"}
        />
      )}
      <AccountPage hidden={navigationContext.currentSubapp !== "account"} />
      <ArchivePage hidden={navigationContext.currentSubapp !== "archive"} />
      {isAdminAccount && (
        <UserPage hidden={navigationContext.currentSubapp !== "user"} />
      )}
    </>
  );

  return (
    <div className="app-container ">
      <div
        className="navbar-vertical-container"
        style={{
          maxWidth: navigationContext.expandedMenu
            ? DIMENSIONS.navBarWidthOpen
            : DIMENSIONS.navBarWidthClose,
        }}
      >
        <div className="navbar-container">
          {subappMenu()}
          <div className="bottom-container">
            <Button
              icon={`pi ${navigationContext.expandedMenu ? "pi-angle-double-left" : "pi-angle-double-right"}`}
              text
              size="small"
              rounded
              className="text-white opacity-50 hover:opacity-100 active:opacity-100"
              onClick={() => setExpandedMenu(!navigationContext.expandedMenu)}
            />
            {navigationContext.expandedMenu ? (
              <div onClick={showBugReport} className="version">
                {`Un bug ?`}
              </div>
            ) : (
              <NavIconButton
                key={"bug-report"}
                index={"bug-report"}
                src={MEDIAS.bug}
                onClick={showBugReport}
                isActive={false}
              />
            )}
            {navigationContext.expandedMenu ? (
              <div onClick={handleLogout} className="version">
                {`Se déconnecter`}
              </div>
            ) : (
              <NavIconButton
                key={"logout"}
                index={"logout"}
                src={MEDIAS.logout}
                onClick={handleLogout}
                isActive={false}
              />
            )}

            <div className="version" onClick={showReleaseNote}>
              {navigationContext.expandedMenu
                ? `Version ${CONSTANTS.VERSION}`
                : `${CONSTANTS.VERSION}`}
            </div>
          </div>
        </div>
      </div>
      {/* Page content */}
      <div className="w-full overflow-x-scroll overflow-y-hidden">{subapp}</div>
    </div>
  );
}

export default InternalRouter;

import React, { useEffect, useMemo, useState } from "react";
import { privateFetch } from "../../../utils/apiHelper";
import { useAccountContext } from "../../../contexts/AccountContext";
import "../../Pages.scss";
import { TransactionDatatable } from "../../../modules/transaction/TransactionDatatable";
import moment from "moment";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { useArchiveContext } from "../../../contexts/ArchiveContext";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import { useDebouncedCallback } from "use-debounce";
import slugify from "react-slugify";
import TransactionOverviewPanel from "../../../modules/transaction/TransactionOverviewPanel";

function ArchivePage({ hidden }) {
  const { archiveContext } = useArchiveContext();
  const { accountContext, isIntermediateAccount, isInvestorAccount } =
    useAccountContext();
  const [datatableLoading, setDatatableLoading] = useState(true);
  const [transactionList, setTransactionList] = useState([]);
  const [panelTransaction, setPanelTransaction] = useState(undefined);

  useEffect(() => {
    refreshTransactionList();
  }, [archiveContext, archiveContext.sortOption]);

  const refreshTransactionList = useDebouncedCallback(() => {
    fetchTransactionList();
  }, 500);

  const currentUserUrl = useMemo(() => {
    if (isIntermediateAccount) return "intermediate";
    if (isInvestorAccount) return "investor";
    else return "cu";
  }, [isIntermediateAccount, isInvestorAccount]);

  const fetchTransactionList = async () => {
    setDatatableLoading(true);
    await privateFetch(
      "GET",
      `/${currentUserUrl}/${accountContext.id}/transaction/${
        currentUserUrl === "cu"
          ? `?rows=${archiveContext.options?.rows}&page=${
              archiveContext.options?.page
            }&offset=${archiveContext.options?.offset}&query=${slugify(archiveContext.filters?.query)}&status=${archiveContext.filters?.status}&nature=${archiveContext.filters?.nature}&linked_scopes=${archiveContext.filters?.scopeList?.map(
              (s) => s.id
            )}&intermediates=${archiveContext.filters?.intermediateList?.map(
              (i) => i.id
            )}&types=${archiveContext.filters?.transactionTypeList?.map(
              (i) => i.id
            )}&sort_field=${archiveContext.sortOption?.sortField}&sort_order=${archiveContext.sortOption?.sortOrder}`
          : ""
      }`
    ).then((res) => {
      if (res) {
        setTransactionList(res);
        setDatatableLoading(false);
      }
    });
  };

  const handleSelectTransaction = (targetTransaction) => {
    setPanelTransaction(targetTransaction);
  };

  return (
    <div className="h-full w-full" hidden={hidden}>
      {/* Header */}
      <BreadCrumb
        items={[
          {
            label: "Historique des transactions",
            isActive: true,
          },
        ]}
      />

      {/* Content */}
      <div className="hidden-scrollbar main-content-wrapper">
        <div className="p-4 w-full overflow-y-hidden">
          <div className="full-page-datatable">
            <TransactionDatatable
              loading={datatableLoading}
              data={transactionList}
              exportFileName={`export_transactions_${moment().format("DD/MM/YYYY")}`}
              selectTransaction={handleSelectTransaction}
              showInvestorName
              showControls={true}
            />
          </div>
        </div>
      </div>

      <TransactionOverviewPanel
        transaction={panelTransaction}
        isOpen={!!panelTransaction}
        handleClose={() => setPanelTransaction(undefined)}
      />
    </div>
  );
}

export default ArchivePage;

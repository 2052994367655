import React from "react";
import { Sidebar } from "primereact/sidebar";
import TitlePage from "../../components/TitlePage";
import Section from "../../components/Display/Section";
import DataTag from "../../components/Display/DataTag";
import Data from "../../components/Display/Data";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { MEDIAS } from "../../ressources/MEDIAS";
import { Divider } from "primereact/divider";
import DocumentList from "../../components/DocumentList";

function TransactionOverviewPanel({
  isOpen,
  handleClose,

  transaction,
}) {
  const { getScopeFromId } = useAuthorizationsContext();

  return (
    <Sidebar
      style={{ width: 800, paddingLeft: 10, paddingRight: 10 }}
      header={<TitlePage text={`Détail d'une transaction`} />}
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      {transaction && (
        <div className="sidebar-content-container">
          <Section label="Informations">
            <div className="grid grid-cols-2 gap-4">
              <Data
                label={"Investisseur"}
                value={`${transaction.investor.first_name} ${transaction.investor.name}`}
                large
                className="col-span-2"
              />
              <DataTag
                label={"Périmètre"}
                value={getScopeFromId(transaction.linked_scope).label}
                bgColor={
                  "bg-" + getScopeFromId(transaction.linked_scope).main_color
                }
                rounded
              />
              <DataTag
                label={"Statut"}
                value={
                  CONSTANTS.TRANSACTION_STATUS.find(
                    (ts) => ts.value === transaction.status
                  ).label
                }
                bgColor={
                  CONSTANTS.TRANSACTION_STATUS.find(
                    (ts) => ts.value === transaction.status
                  ).color
                }
                rounded
              />
              <DataTag
                label={"Nature"}
                value={
                  CONSTANTS.TRANSACTION_NATURE_TYPES.find(
                    (nt) => nt.value === transaction.nature
                  ).label
                }
                bgColor={
                  CONSTANTS.TRANSACTION_NATURE_TYPES.find(
                    (nt) => nt.value === transaction.nature
                  ).color
                }
                rounded
              />
              <DataTag
                label={"Type"}
                value={transaction.transaction_type.label}
                large
                rounded
              />

              <Data
                label={"Date d'éxecution"}
                value={transaction.execution_date}
                type={"date"}
              />
              <Data label={"ID"} value={transaction.id} />
              <DataTag
                label={"Intermédiaire"}
                value={transaction.intermediate?.name || ""}
                icon={MEDIAS.briefcaseWhite}
              />
              <DataTag
                icon={MEDIAS.userWhite}
                label={"Compte intermédiaire"}
                value={transaction.intermediate_account?.alias || ""}
              />

              <Data
                label={"Montant"}
                value={transaction.amount}
                type={"currency"}
              />
              <Data label={"Nb de parts"} value={transaction.share_nb} />
              <Data
                label={"Frais (en %)"}
                value={transaction.fee_rate}
                type={"percentage"}
              />
              <Data
                label={"Frais (en €)"}
                value={
                  transaction.fee_rate !== null
                    ? transaction.amount * transaction.fee_rate
                    : undefined
                }
                type={"currency"}
              />
            </div>
            <Section label="Documents">
              <DocumentList
                documents={transaction.documents}
                isReadOnly={true}
              />
            </Section>
          </Section>
        </div>
      )}
    </Sidebar>
  );
}

export default TransactionOverviewPanel;

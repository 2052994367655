import { CONSTANTS } from "../ressources/CONSTANTS";

export const getCurrentReleaseNote = () => {
  try {
    return RELEASE_NOTES.find((rn) => rn.version === CONSTANTS.VERSION);
  } catch (error) {
    console.log(
      `Not release not has been found for version ${CONSTANTS.VERSION}`
    );
    return null;
  }
};

const RELEASE_NOTES = [
  {
    version: "2.7.0",
    date: "11/03/2025",
    title: null,
    intro: null,
    newFeatures: [
      "Les comptes investisseurs peuvent maintenant uploader eux-mêmes leurs documents. Ils peuvent également les modifier et les supprimer tant qu'ils ne sont pas validés par des administrateurs. ",
      `Ajout d'un paneau latéral pour consulter le détail d'une transaction depuis la page "Transactions" pour les comptes intermédiaires et investisseurs.`,
    ],
    fixes: [
      "Résolution d'un bug du localStorage qui empechait de voir les lignes de certains tableaux.",
    ],
  },
  {
    version: "2.6.0",
    date: "06/02/2025",
    title: null,
    intro: null,
    newFeatures: [
      "Ajout d'un tableau récapitulatif de toutes les transactions pour les comptes internes, avec possibilité de filtrage, de tri et d'export CSV.",
      "Les comptes internes sont notifiés par mail dès qu'un intermédiaire crée une transaction.",
      "Les comptes internes peuvent maintenant créer des comptes investisseurs.",
    ],
    fixes: [
      "Un compte investisseur voit maintenant toutes ses transactions, pas seulement celles validées.",
      "Le statut 'En attente' est maintenant l'état par défaut lors de la création d'une transaction.",
    ],
  },
  {
    version: "2.5.0",
    date: "17/12/2024",
    title: null,
    intro: null,
    newFeatures: [
      "Ajout des comptes investisseurs",
      "Il est maintenant possible de lier des transactions à des Résidences (Résid'ens) ou à des Projets (Terrafine)",
      "Ajout de la somme des transactions en attente dans l'export CSV des investisseurs.",
      'Génération des documents "Relevé de mouvements" et "Attestation fiscale" pour un ou tous les investisseurs.',
      'Ajout des champs "Ville", "Code postal" et "Représenté par" sur les intermédiaires.',
      'Ajout des champs "Ville", "Code postal", "Adresse", "Email", "Téléphone" et "Représenté par" dans les exports CSV du tableau des intermédiaires.',
    ],
    fixes: [
      "Correction du bug qui rendait les éléments violet (Resid'ens) et vert (Terrafine) invisibles.",
      "La recherche par nom fonctionne maintenant pour tous les bénéficiaires et toutes les résidences.",
      "Amélioration de l'affichage des surfaces en hectare.",
      "Les comptes intermédiaires ne peuvent plus modifier les transactions validées.",
      "Affichage du favicon dans les onglets du navigateur.",
      "Nettoyage de la base de données investisseur. Suppression des IDs : 423, 372, 368, 369, 387",
    ],
  },
  {
    version: "2.4.0",
    date: "08/10/2024",
    title: null,
    intro: "Nouvelle version mineure pour l'application Crescendo Imvest !",
    newFeatures: [
      "Ajout du module de report de bug",
      "Ajout du module de release note",
      "Refonte complète de la navigation",
      'Ajout d\'un indicateur permettant de voir rapidement quelles transactions sont "en attente de validation" dans le tableau des investisseurs.',
      "Module de commentaire interne au niveau des investisseurs et des intermédiaires.",
      "Nouveau mode d'affichage pour le menu latéral.",
      "Améliorations visuelles et fonctionnelles diverses.",
      "Modification du système de filtrage et de tri des tableaux.",
      "Augmentation signifiicative des performances de l'application, notamment du chargement du tableau d'investisseurs.",
    ],
    fixes: ["L'utilisation sur mobile est désormais bloquée."],
  },
];

import { MEDIAS } from "./MEDIAS";
import { COLORS } from "./STYLING";

export const CONSTANTS = {
  VERSION: "2.7.0",
  maxImageSize: 10 * 1000000,
  SUBAPP_LABELS: {
    terrafine: {
      INVESTOR: "Investisseur",
      PROJECT: "Projet",
      INTERMEDIATE: "Intermédiaire",
    },
    residens: {
      INVESTOR: "Investisseur",
      PROJECT: "Résidence",
      INTERMEDIATE: "Intermédiaire",
    },
  },
  AUTH_INFO: {
    FORBIDDEN: {
      icon: MEDIAS.forbidden,
      bgColor: "bg-slate-500",
    },
    READ_ONLY: {
      icon: MEDIAS.read,
      bgColor: "bg-custom-green",
    },
    READ_WRITE: {
      icon: MEDIAS.write,
      bgColor: "bg-custom-green-dark",
    },
  },
  DASHBOARD: {
    TRANSACTION_SUM: {
      columnLabel: "Somme",
    },
    TRANSACTION_BALANCE: {
      columnLabel: "Balance",
    },
  },
  GENERATED_DOCUMENT_TYPES: [
    {
      label: "Attestation fiscale",
      value: "fiscal",
    },
    {
      label: "Relevé de mouvements",
      value: "report",
    },
  ],
  ACCOUNT_TYPES: [
    { label: "Interne", value: "EMPLOYEE", color: "bg-custom-green" },
    { label: "Administrateur", value: "ADMIN", color: "bg-custom-green-dark" },
    { label: "Intermédiaire", value: "INTERMEDIATE", color: undefined },
    { label: "Investisseur", value: "INVESTOR", color: "bg-custom-orange" },
  ],
  GENDER_TYPES: [
    { label: "Madame", value: "Madame" },
    { label: "Monsieur", value: "Monsieur" },
  ],
  DOCUMENTS: [
    {
      type: "other",
      label: "Autre document",
    },
    {
      type: "id_card",
      label: "Carte d'identitié",
    },
    {
      type: "id_card_verso",
      label: "Carte d'identitié (Verso)",
    },
    {
      type: "residence_proof",
      label: "Justificatif de domicile",
    },
    {
      type: "ps_test",
      label: "Test adéquation PS",
    },
    {
      type: "ps_subscription",
      label: "Bulletin de souscription PS",
    },
    {
      type: "tp_test",
      label: "Test adéquation TP",
    },
    {
      type: "tp_subscription",
      label: "Bulletin de souscription TP",
    },
    {
      type: "tp_contract",
      label: "Contrat d’émission TP",
    },
    {
      type: "cc_convention",
      label: "Convention de compte courant",
    },
    {
      type: "intermediate_kbis",
      label: "K-Bis",
    },
    {
      type: "intermediate_rcpro",
      label: "RCPRO",
    },
    {
      type: "intermediate_gf",
      label: "GF",
    },
    {
      type: "intermediate_iban",
      label: "IBAN",
    },
    {
      type: "intermediate_convention",
      label: "Convention de partenariat",
    },
  ],
  TOOLBOX_DOCUMENTS: [
    {
      key: "library_documents",
      type: "toolbox_library",
      label: "Général",
      required: false,
    },
    {
      key: "ps_documents",
      type: "toolbox_ps",
      label: "Parts Sociales",
      required: false,
    },
    {
      key: "tp_documents",
      type: "toolbox_tp",
      label: "Titres Participatifs",
      required: false,
    },
  ],
  INVESTOR_DOCUMENTS: [
    {
      type: "id_card",
      label: "Carte d'identitié",
      required: true,
    },
    {
      type: "residence_proof",
      label: "Justificatif de domicile",
      required: true,
    },
    {
      type: "other",
      label: "Autre document",
      required: false,
    },
    {
      type: "id_card_verso",
      label: "Carte d'identitié (Verso)",
      required: false,
    },
  ],
  TRANSACTION_DOCUMENTS: {
    cc: [
      {
        type: "cc_convention",
        label: "Convention de compte courant",
        required: true,
      },
      {
        type: "other",
        label: "Autre document",
        required: false,
      },
    ],
    ps: [
      {
        type: "ps_test",
        label: "Test d'adéquation",
        required: true,
      },
      {
        type: "ps_subscription",
        label: "Bulletin de souscription",
        required: true,
      },
      {
        type: "other",
        label: "Autre document",
        required: false,
      },
    ],
    tp: [
      {
        type: "tp_test",
        label: "Test d'adéquation",
        required: true,
      },
      {
        type: "tp_subscription",
        label: "Bulletin de souscription",
        required: true,
      },
      {
        type: "tp_contract",
        label: "Contrat d’émission TP",
        required: true,
      },
      {
        type: "other",
        label: "Autre document",
        required: false,
      },
    ],
  },
  INTERMEDIATE_DOCUMENTS: [
    {
      type: "intermediate_kbis",
      label: "K-Bis",
      required: true,
    },
    {
      type: "intermediate_rcpro",
      label: "RCPRO",
      required: true,
    },
    {
      type: "intermediate_gf",
      label: "GF",
      required: true,
    },
    {
      type: "intermediate_iban",
      label: "IBAN",
      required: true,
    },
    {
      type: "intermediate_convention",
      label: "Convention de partenariat",
      required: true,
    },
    {
      type: "other",
      label: "Autre document",
      required: false,
    },
  ],
  TRANSACTION_NATURE_TYPES: [
    {
      label: "Investissement",
      value: "Investissement",
      color: "bg-custom-green-dark",
    },
    {
      label: "Remboursement",
      value: "Remboursement",
      color: "bg-custom-orange",
    },
  ],
  TRANSACTION_TYPES: [
    { label: "Parts Sociales", value: "Parts Sociales" },
    { label: "Compte Courant", value: "Compte Courant" },
    { label: "Titre Participatif", value: "Titre Participatif" },
  ],

  TRANSACTION_STATUS: [
    {
      label: "Validée",
      value: "validated",
      color: "bg-custom-green",
    },
    {
      label: "En attente",
      value: "pending",
      color: "bg-custom-orange",
    },
    {
      label: "Incomplète",
      value: "incomplete",
      color: "bg-custom-red",
    },
    {
      label: "Abandonnée",
      value: "refused",
      color: "bg-custom-red",
    },
  ],
  PROFILE_TYPES: [
    { label: "Particulier", value: "Particulier" },
    { label: "Entreprise", value: "Entreprise" },
    { label: "Fond d'investissement", value: "Fond d'investissement" },
    {
      label: "Autre (collectivité, asso, ...)",
      value: "Autre (collectivité, asso, ...)",
    },
  ],
  COLLEGE_TYPES: [
    { label: "Financeur", value: "Financeur" },
    { label: "Bénéficiaire", value: "Bénéficiaire" },
    { label: "Salarié", value: "Salarié" },
  ],
  INVESTOR_STATES: [
    { label: "Actif", value: true, bgColor: "bg-custom-green" },
    { label: "Inactif", value: false },
  ],
  AUTHORIZATION_TYPES: [
    { label: "Aucune", value: "FORBIDDEN" },
    { label: "Lecture seule", value: "READ_ONLY" },
    { label: "Lecture & écriture", value: "READ_WRITE" },
  ],
  RESIDENCE_STATES: [
    {
      label: "Ouvert",
      color: "bg-custom-green-dark",
    },
    {
      label: "En chantier",
      color: "bg-custom-green",
    },
    {
      label: "En étude",
      color: "bg-custom-orange",
    },
    {
      label: "Sans suite",
      color: "bg-custom-red",
    },
  ],
  RESIDENCE_TYPES: [
    {
      label: "Construction",
      color: COLORS.greyButton,
    },
    {
      label: "Réhabilitation",
      color: COLORS.greyButton,
    },
  ],
  PROJECT_STATES: [
    {
      label: "En cours",
      color: "bg-custom-green-dark",
    },
    {
      label: "Réalisé",
      color: "bg-custom-green",
    },
    {
      label: "Sans suite",
      color: "bg-custom-red",
    },
    {
      label: "Revendu",
      color: "bg-custom-orange",
    },
  ],
  PROJECT_TYPES: ["Cession bail", "Installation", "Croissance externe"],
  COMMITEE_DECISIONS: [
    { label: "Favorable", value: true },
    { label: "Défavorable", value: false },
  ],
  REGIONS: [
    "Bretagne",
    "Centre - Val de Loire",
    "Corse",
    "Grand Est",
    "Hauts-de-France",
    "Île-de-France",
    "Normandie",
    "Nouvelle-Aquitaine",
    "Occitanie",
    "Pays de la Loire",
    "Provence - Alpes - Côte d'Azur",
    "Outre-mer",
  ],
  QUALITY_APPROACH_TYPES: ["Label HVE3", "Label Bas Carbone"],
  AGRICULTURE_TYPES: [
    { label: "Bio", value: true },
    { label: "Conventionnel", value: false },
  ],
};

import React, { useMemo, useRef } from "react";
import { TransactionDatatable } from "../transaction/TransactionDatatable";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import Data from "../../components/Display/Data";
import DataTag from "../../components/Display/DataTag";
import moment from "moment";
import Section from "../../components/Display/Section";
import DataMultipleTags from "../../components/Display/DataMultipleTags";
import { MEDIAS } from "../../ressources/MEDIAS";
import { useNavigationContext } from "../../contexts/NavigationContext";
import { useAccountContext } from "../../contexts/AccountContext";
import InvestorAccount from "./InvestorAccount";
import { Button } from "primereact/button";
import DocumentList from "../../components/DocumentList";
import { CONSTANTS } from "../../ressources/CONSTANTS";

export const InvestorDetail = ({
  investor,
  documentList,
  handleInvestorUpdate,
  selectTransaction,
  handleCreateTransaction,
  handleUploadDocument,
  handleUpdateDocument,
  handleCreateAccount,
  handleDeleteAccount,
  handleGenerateDocument,
  accountHasWriteAuthOnInvestor,
}) => {
  const exportTransactionRef = useRef(null);
  const { getScopeFromId } = useAuthorizationsContext();
  const { navigateToIntermediate } = useNavigationContext();
  const {
    isIntermediateAccount,
    isAdminAccount,
    isInvestorAccount,
    isInternAccount,
  } = useAccountContext();

  const handleExistingDocument = (d) => {
    handleUpdateDocument(d, d.type);
  };

  const handleMissingDocument = (d) => {
    handleUpdateDocument(undefined, d.type);
  };

  const scopeTags = useMemo(
    () =>
      investor?.linked_scopes.map((lsId) => ({
        value: getScopeFromId(lsId).label,
        bgColor: "bg-" + getScopeFromId(lsId).main_color,
        rounded: true,
      })),
    [investor]
  );

  const informationActions = handleInvestorUpdate && [
    {
      onClick: handleInvestorUpdate,
      label: "Modifier les informations",
      icon: "pi-pencil",
    },
  ];

  const transactionActions = handleCreateTransaction && [
    {
      onClick: handleCreateTransaction,
      label: "Ajouter une transaction",
      icon: "pi-plus",
    },
  ];

  const documentActions = handleUploadDocument && [
    {
      onClick: handleUploadDocument,
      label: "Ajouter un document",
      icon: "pi-plus",
    },
  ];

  const accountActions = investor?.account
    ? [
        {
          onClick: handleDeleteAccount,
          label: "Supprimer le compte",
          icon: "pi-trash",
        },
      ]
    : [
        {
          onClick: handleCreateAccount,
          label: "Ajouter un compte",
          icon: "pi-plus",
        },
      ];

  if (!investor) return null;
  return (
    <div className="main-content-container ">
      <Section label="Informations" actions={informationActions}>
        <div className="data-section-row">
          <Data key="name" label={"Nom"} value={investor.name} large />
          <Data
            key="first_name"
            label={"Prénom"}
            value={investor.first_name}
            large
          />
          <DataTag
            key="college"
            label={"Collège"}
            value={investor.college}
            rounded
          />
          <DataTag
            key="profil"
            label={"Profil"}
            value={investor.profile}
            rounded
          />
          <Data key="id" label={"ID"} value={investor.id} />
        </div>
        <div className="data-section-row">
          <DataMultipleTags label={"Périmètres"} tags={scopeTags || []} large />
          {!isIntermediateAccount && (
            <DataTag
              key="intermediate"
              label={"Intermédiaire"}
              value={investor.intermediate?.name}
              icon={MEDIAS.briefcaseWhite}
              onClick={() =>
                isAdminAccount && navigateToIntermediate(investor.intermediate)
              }
            />
          )}
        </div>

        <div className="data-section-row">
          <Data
            key="address"
            label={"Adresse complète"}
            large
            value={
              investor.address + ", " + investor.zip_code + " " + investor.city
            }
          />
          <Data key="email" label={"Email"} value={investor.email} large />
          <Data key="phone" label={"Téléphone"} value={investor.phone} />
        </div>

        <div className="data-section-row">
          <Data key="gender" label={"Civilité"} value={investor.gender} large />
          <Data
            key="birthPlace"
            label={"Lieu de naissance"}
            value={investor.birth_place}
            large
          />
          <Data
            key="birthDate"
            label={"Date de naissance"}
            value={investor.birth_dt}
            type={"date"}
          />
        </div>
      </Section>

      {!isInvestorAccount && (
        <Section label="Transactions" actions={transactionActions}>
          <TransactionDatatable
            exportRef={exportTransactionRef}
            data={investor}
            exportFileName={`export_transactions_${investor.name}_${investor.last_name}_${moment().format("DD/MM/YYYY")}`}
            selectTransaction={selectTransaction}
          />
        </Section>
      )}

      {accountHasWriteAuthOnInvestor && isInternAccount && (
        <Section label="Compte associé" actions={accountActions}>
          <InvestorAccount
            data={investor?.account}
            handleDeleteAccount={handleDeleteAccount}
          />
        </Section>
      )}

      <Section label="Documents" actions={documentActions}>
        <DocumentList
          handleMissingDocument={handleMissingDocument}
          handleExistingDocument={handleExistingDocument}
          requiredDocuments={CONSTANTS.INVESTOR_DOCUMENTS}
          documents={documentList}
        />
      </Section>

      {!isInvestorAccount && !isIntermediateAccount && (
        <Section label="Autres actions">
          <div className="flex flex-row gap-x-3">
            <Button
              key={"report"}
              onClick={() => handleGenerateDocument(investor)}
              icon={"pi pi-cloud-download"}
              label={"Générer un document"}
              outlined
            />
          </div>
        </Section>
      )}
    </div>
  );
};

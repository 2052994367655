import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { privateFetch } from "../../utils/apiHelper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { applyCurrencyFormat } from "../../utils/datatableHelper";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Tag from "../../components/Tag";
import { MEDIAS } from "../../ressources/MEDIAS";
import { useArchiveContext } from "../../contexts/ArchiveContext";
import { useAccountContext } from "../../contexts/AccountContext";
import { Button } from "primereact/button";
import Search from "../../components/Datatable/Search";
import Filter from "../../components/Datatable/Filter";

export const TransactionDatatable = ({
  selectTransaction,
  data,
  showControls,
  exportFileName,
  showInvestorName,
  loading,
}) => {
  const exportRef = useRef(null);
  const { getScopeFromId, getAuthorizedScopeList } = useAuthorizationsContext();
  const {
    archiveContext,
    setArchiveSort,
    setArchiveOptions,
    setArchiveFilter,
  } = useArchiveContext();
  const { accountContext, isInternAccount } = useAccountContext();
  const [intermediateList, setIntermediateList] = useState(undefined);
  const [transactionTypeList, setTransactionTypeList] = useState(undefined);

  useEffect(() => {
    fetchIntermediateList();
    fetchTransactionTypeList();
  }, []);

  const fetchTransactionTypeList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/transaction/type/`
    ).then((res) => {
      setTransactionTypeList(res);
    });
  };

  const fetchIntermediateList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/autocomplete/`
    ).then((res) => {
      if (res) setIntermediateList(res);
    });
  };

  const executionDateTemplate = (rowData) => {
    return moment(rowData.execution_date).format("DD/MM/YYYY");
  };

  const scopesBodyTemplate = (rowData) => {
    return (
      <Tag
        value={getScopeFromId(rowData.linked_scope).label}
        bgColor={"bg-" + getScopeFromId(rowData.linked_scope).main_color}
        rounded
      />
    );
  };

  const intermediateBodyTemplate = (rowData) => {
    if (!rowData.intermediate)
      return <span className="italic text-gray-400">Non renseigné</span>;
    return (
      <div className="flex gap-x-2">
        <Tag value={rowData.intermediate.name} icon={MEDIAS.briefcaseWhite} />
        {rowData.intermediate_account && (
          <Tag
            icon={MEDIAS.userWhite}
            value={rowData.intermediate_account.alias}
          />
        )}
      </div>
    );
  };

  const natureBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          CONSTANTS.TRANSACTION_NATURE_TYPES.find(
            (nt) => nt.value === rowData.nature
          ).label
        }
        bgColor={
          CONSTANTS.TRANSACTION_NATURE_TYPES.find(
            (nt) => nt.value === rowData.nature
          ).color
        }
        rounded
      />
    );
  };

  const stateBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          CONSTANTS.TRANSACTION_STATUS.find((ts) => ts.value === rowData.status)
            .label
        }
        bgColor={
          CONSTANTS.TRANSACTION_STATUS.find((ts) => ts.value === rowData.status)
            .color
        }
        rounded
      />
    );
  };

  const onSort = (sort) => {
    setArchiveSort(sort);
  };

  const onPage = (event) => {
    setArchiveOptions({
      page: event.page,
      offset: event.first,
      rows: event.rows,
    });
  };

  const handleFilter = (key, value) => {
    setArchiveFilter(key, value);
  };

  const actions = (
    <Button
      key={"csv"}
      size={"small"}
      onClick={() => exportRef.current.exportCSV()}
      icon={"pi pi-cloud-download"}
      label={"Exporter la vue actuelle"}
      outlined
    />
  );

  const filters = (
    <div className="flex flex-wrap gap-3">
      {/*
      <Search
        placeholder="Investisseur"
        handleSearch={(value) => handleFilter("query", value)}
        value={archiveContext.filters?.query}
      />
      */}
      <Filter
        key="nature"
        options={CONSTANTS.TRANSACTION_NATURE_TYPES}
        value={archiveContext.filters?.nature}
        onChange={(value) => handleFilter("nature", value)}
        emptyLabel="Nature"
      />
      <Filter
        key="status"
        options={CONSTANTS.TRANSACTION_STATUS}
        value={archiveContext.filters?.status}
        onChange={(value) => handleFilter("status", value)}
        emptyLabel="Statut"
      />

      <Filter
        key="transactionType"
        options={transactionTypeList}
        value={archiveContext.filters?.transactionTypeList}
        onChange={(value) => handleFilter("transactionTypeList", value)}
        emptyLabel="Filtrer par type"
        optionLabel="label"
      />

      <Filter
        key="scope"
        options={getAuthorizedScopeList()}
        value={archiveContext.filters?.scopeList}
        onChange={(value) => handleFilter("scopeList", value)}
        emptyLabel="Périmêtre"
      />
      <Filter
        key="intermediate"
        options={intermediateList}
        value={archiveContext.filters?.intermediateList}
        onChange={(value) => handleFilter("intermediateList", value)}
        emptyLabel="Filtrer par intermédiaire"
        optionLabel="name"
      />
    </div>
  );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totaux" />
        {showInvestorName && <Column />}
        <Column />
        <Column footer={`${data?.transactions?.length} transations`} />
        <Column footer={`${applyCurrencyFormat(data.balance)}`} />
        <Column footer={`${data.share_balance}`} />
        <Column />
        <Column />
        <Column />
        {getAuthorizedScopeList().length > 1 && <Column />}
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="flex flex-col gap-4">
      {isInternAccount && showControls && (
        <div className="flex flex-row justify-between w-full">
          {filters}
          {actions}
        </div>
      )}
      <DataTable
        ref={exportRef}
        value={data.transactions}
        scrollable={showControls}
        emptyMessage={"Pas de transaction à afficher"}
        selectionMode="single"
        onRowSelect={(e) => selectTransaction(e.data)}
        exportFilename={exportFileName}
        csvSeparator=";"
        footerColumnGroup={footerGroup}
        onSort={onSort}
        sortMode={"single"}
        sortField={archiveContext.sortOption?.sortField}
        sortOrder={archiveContext.sortOption?.sortOrder}
        onPage={onPage}
        lazy
        paginator
        rows={archiveContext.options?.rows}
        first={archiveContext.options?.offset}
        rowsPerPageOptions={[5, 20, 50, 100, 200, 500]}
        totalRecords={data.total_transaction}
        loading={loading}
      >
        {showInvestorName && (
          <Column
            header="Investisseur"
            field="investor.name,investor.first_name"
            className="font-bold"
            exportField={(rowData) =>
              `${rowData.investor.first_name} ${rowData.investor.name}`
            }
            body={(rowData) =>
              `${rowData.investor.first_name} ${rowData.investor.name}`
            }
          ></Column>
        )}

        <Column
          field="nature"
          header="Nature"
          body={natureBodyTemplate}
        ></Column>
        <Column field="status" header="État" body={stateBodyTemplate}></Column>
        <Column field="transaction_type.label" header="Type"></Column>
        <Column
          sortable={showControls}
          field="amount"
          header="Montant"
          body={(rowData) => applyCurrencyFormat(rowData.amount)}
        ></Column>
        <Column
          sortable={showControls}
          dataType="numeric"
          field="share_nb"
          header="Nb"
        ></Column>
        <Column
          sortable={showControls}
          body={executionDateTemplate}
          field="execution_date"
          header="Date"
          dataType="date"
        ></Column>
        <Column
          field="intermediate"
          header="Intermédiaire"
          body={intermediateBodyTemplate}
        ></Column>
        {getAuthorizedScopeList().length > 1 && (
          <Column
            field="linked_scope"
            header="Périmètre"
            body={scopesBodyTemplate}
          ></Column>
        )}
        <Column field="id" header="ID"></Column>
      </DataTable>
    </div>
  );
};
